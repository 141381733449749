import ConditionalLink from "@/react-app/components/ConditionalLink/ConditionalLink";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "@/react-app/hooks/useXxlMediaQuery";
import { containerMaxWidth } from "@/react-app/styles/xxl-theme";
import { siteContainerMediaQuery } from "@/react-app/utils/xxl-screen";
import { getImagePriority } from "@/react-components/Banners/Shared/BannerContent/BannerContent.helper";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { ArrowRight } from "@xxl/icons";
import { NextImage } from "../common/NextImage/NextImage";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";
import { ContentProductCarouselComponent } from "@/components/common/ContentProductCarousel/ContentProductCarousel";

import {
  getButtonVariant,
  getUniqueProps,
  type CampaignCarouselProps,
} from "./CampaignCarousel.helper";
import {
  Description,
  DisclaimerInformationWrapper,
  HeaderContainer,
  Link,
  TextContainer,
  Title,
} from "./CampaignCarousel.styled";

const disclaimerinformationIconSizes = {
  mobile: 18,
  laptop: 24,
};

const CampaignCarousel = (props: CampaignCarouselProps) => {
  const {
    backgroundColor,
    image,
    title,
    description,
    TopComponent,
    disclaimerInformation,
    recommendationInfo,
    isHighPrioComponent = false,
  } = props;
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const { button, link } = getUniqueProps(props);
  const headerHeight = isLaptop ? 210 : 200;
  const disclaimerInformationIconsSize = isLaptop
    ? disclaimerinformationIconSizes.laptop
    : disclaimerinformationIconSizes.mobile;
  const hasDisclaimerInformation = hasValue(disclaimerInformation);

  return (
    <>
      <SiteContainer hideBottomPadding={true}>
        <div style={{ position: "relative" }}>
          {hasDisclaimerInformation && (
            <DisclaimerInformationWrapper>
              <DisclaimerInformation
                buttonText={t("general.close")}
                title={t("general.information")}
                iconSize={disclaimerInformationIconsSize}
                color={disclaimerInformation.buttonColor}
              >
                {disclaimerInformation.description}
              </DisclaimerInformation>
            </DisclaimerInformationWrapper>
          )}

          <ConditionalLink
            condition={hasValue(link?.href)}
            url={link?.href ?? ""}
          >
            <div style={{ height: `${headerHeight}px`, position: "relative" }}>
              {isNotNullOrUndefined(image) && (
                <NextImage
                  src={image.desktopSrc}
                  alt={image.alt}
                  hotspot={image.hotspot}
                  height={headerHeight}
                  width={isLaptop ? 1440 : 375}
                  sizes={`${siteContainerMediaQuery} ${containerMaxWidth}px, 97vw`}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "1332px",
                  }}
                  {...getImagePriority(isHighPrioComponent)}
                />
              )}

              <HeaderContainer
                backgroundColor={
                  image === undefined ? backgroundColor : "transparent"
                }
              >
                <TextContainer
                  hasDisclaimerInformation={hasDisclaimerInformation}
                  disclaimerInformationIconsSize={
                    disclaimerInformationIconsSize
                  }
                >
                  {TopComponent !== undefined && TopComponent}
                  <Title style={{ color: title.color }}>{title.text}</Title>
                  {description !== undefined && (
                    <Description style={{ color: description.color }}>
                      {description.text}
                    </Description>
                  )}
                </TextContainer>
                {button !== null && (
                  <>
                    {isLaptop ? (
                      <XxlButton
                        style={{ width: "fit-content" }}
                        href={button.href}
                        variant={getButtonVariant(
                          isLaptop,
                          button.backgroundColor
                        )}
                      >
                        <span>{button.text}</span>
                        <ArrowRight
                          fontSize={20}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </XxlButton>
                    ) : (
                      <Link
                        href={button.href}
                        style={{ color: button.backgroundColor }}
                      >
                        {button.text}
                        <ArrowRight
                          fontSize={16}
                          style={{
                            position: "relative",
                            top: "3px",
                            marginLeft: "6px",
                          }}
                        />
                      </Link>
                    )}
                  </>
                )}
              </HeaderContainer>
            </div>
          </ConditionalLink>
        </div>
      </SiteContainer>
      <ContentProductCarouselComponent
        backgroundColor={recommendationInfo.colorTheme}
        hasTopMargin={false}
        strategy={recommendationInfo.strategy}
        brands={recommendationInfo.brandNames}
        campaigns={recommendationInfo.campaignIds}
        categories={recommendationInfo.categoryIds}
        includedProducts={recommendationInfo.includedProducts}
        productsCount={recommendationInfo.limit}
      />
    </>
  );
};

export { CampaignCarousel };
